/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'pentagon-half': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 1.288l6.578 5.345a.5.5 0 01.161.539l-2.362 7.479a.5.5 0 01-.476.349H8zm7.898 5.536a.5.5 0 00-.162-.538L8.316.256a.5.5 0 00-.631 0L.264 6.286a.5.5 0 00-.162.538l2.788 8.827a.5.5 0 00.476.349h9.268a.5.5 0 00.476-.35z"/>',
    },
});
